var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",staticStyle:{"height":"100%"}},[_c('v-row',{staticClass:"d-flex align-content-space-between",staticStyle:{"height":"100%"}},[_c('v-col',_vm._b({class:_vm.defaultClassColumn,attrs:{"xl":"6","lg":"6","md":"12","sm":"12","xs":"12","cols":"12"}},'v-col',_vm.autoCompleteColumnProps,false),[_c('v-autocomplete',_vm._b({attrs:{"placeholder":"Selecione","label":"Tipo de arquivo*","items":_vm.getFileTypes,"rules":[_vm.rule.required]},model:{value:(_vm.dataLoad.subject),callback:function ($$v) {_vm.$set(_vm.dataLoad, "subject", $$v)},expression:"dataLoad.subject"}},'v-autocomplete',_vm.autocompleteProps,false))],1),_c('v-col',_vm._b({class:_vm.defaultClassColumn,attrs:{"xl":"6","lg":"6","md":"12","sm":"12","xs":"12","cols":"12"}},'v-col',_vm.autoCompleteColumnProps,false),[_c('v-autocomplete',_vm._b({attrs:{"placeholder":"Selecione","label":Boolean(_vm.mandatorySelected)
          && _vm.mandatorySelected.financialGroupMandatory === true
          ? 'Grupo financeiro*'
          : 'Grupo financeiro',"items":_vm.financialGroups,"rules":Boolean(_vm.mandatorySelected)
          && _vm.mandatorySelected.financialGroupMandatory === true
            ? [_vm.rule.required]
            : []},model:{value:(_vm.dataLoad.financialGroupId),callback:function ($$v) {_vm.$set(_vm.dataLoad, "financialGroupId", $$v)},expression:"dataLoad.financialGroupId"}},'v-autocomplete',_vm.autocompleteProps,false))],1),_c('v-col',_vm._b({class:_vm.defaultClassColumn,attrs:{"xl":"6","lg":"6","md":"12","sm":"12","xs":"12","cols":"12"}},'v-col',_vm.autoCompleteColumnProps,false),[_c('v-autocomplete',_vm._b({attrs:{"placeholder":"Selecione","label":Boolean(_vm.mandatorySelected)
          && _vm.mandatorySelected.contractMandatory === true
          ? 'Contrato*'
          : 'Contrato',"item-color":"textPrimary","items":_vm.contracts,"loading":_vm.loadingContracts,"rules":Boolean(_vm.mandatorySelected)
          && _vm.mandatorySelected.contractMandatory === true
            ? [_vm.rule.required]
            : []},model:{value:(_vm.dataLoad.contractId),callback:function ($$v) {_vm.$set(_vm.dataLoad, "contractId", $$v)},expression:"dataLoad.contractId"}},'v-autocomplete',_vm.autocompleteProps,false))],1),_c('v-col',_vm._b({class:_vm.defaultClassColumn,attrs:{"xl":"6","lg":"6","md":"12","sm":"12","xs":"12","cols":"12"}},'v-col',_vm.autoCompleteColumnProps,false),[_c('v-autocomplete',_vm._b({attrs:{"placeholder":"Selecione","label":Boolean(_vm.mandatorySelected)
          && _vm.mandatorySelected.subContractMandatory === true
          ? 'Subcontrato*'
          : 'Subcontrato',"item-color":"textPrimary","items":_vm.subContracts,"loading":_vm.loadingSubContracts,"rules":Boolean(_vm.mandatorySelected)
          && _vm.mandatorySelected.subContractMandatory === true
            ? [_vm.rule.required]
            : []},model:{value:(_vm.dataLoad.subContractId),callback:function ($$v) {_vm.$set(_vm.dataLoad, "subContractId", $$v)},expression:"dataLoad.subContractId"}},'v-autocomplete',_vm.autocompleteProps,false))],1),_c('v-col',_vm._b({class:_vm.defaultClassColumn,attrs:{"xl":"6","lg":"6","md":"12","sm":"12","xs":"12","cols":"12"}},'v-col',_vm.autoCompleteColumnProps,false),[_c('v-autocomplete',_vm._b({attrs:{"placeholder":"Selecione","label":"Operadora*","items":_vm.mandatories,"rules":[_vm.rule.required]},on:{"change":_vm.setMandatorySelected},model:{value:(_vm.dataLoad.carrierId),callback:function ($$v) {_vm.$set(_vm.dataLoad, "carrierId", $$v)},expression:"dataLoad.carrierId"}},'v-autocomplete',_vm.autocompleteCarrierProps,false))],1),_c('v-col',_vm._b({class:_vm.defaultClassColumn,attrs:{"xl":"6","lg":"6","md":"12","sm":"12","xs":"12","cols":"12"}},'v-col',_vm.autoCompleteColumnProps,false),[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":Boolean(_vm.mandatorySelected)
              && _vm.mandatorySelected.competencyMandatory === true
              ? 'Competência*'
              : 'Competência',"placeholder":"Selecione","readonly":"","outlined":"","dense":"","hide-details":"","rules":Boolean(_vm.mandatorySelected)
              && _vm.mandatorySelected.competencyMandatory === true
                ? [_vm.rule.required]
                : []},model:{value:(_vm.dataLoad.competence),callback:function ($$v) {_vm.$set(_vm.dataLoad, "competence", $$v)},expression:"dataLoad.competence"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"type":"month","locale":"pt-br","color":"wine","no-title":"","scrollable":""},on:{"click":_vm.setCompentence},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.cleanCompetence}},[_vm._v(" LIMPAR ")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.setCompentence}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.validateForm),expression:"!validateForm"}],staticClass:"py-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"error--text text-caption"},[_vm._v(" "+_vm._s(`Por favor, preencha ao menos um campo obrigatório entre ${_vm.fieldsName} e adicione ao menos um arquivo (máximo de ${_vm.rule.getMaxFileSizeMB()} por arquivo).`)+" ")]),_c('p',{staticClass:"error--text text-caption mb-0 pb-0"},[_vm._v(" Extensão de arquivos permitida: .xls, .xlsx, .csv, .txt e .pdf` ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }