import { render, staticRenderFns } from "./FileDropzone.vue?vue&type=template&id=db36c932&scoped=true&"
import script from "./FileDropzone.vue?vue&type=script&lang=js&"
export * from "./FileDropzone.vue?vue&type=script&lang=js&"
import style0 from "./FileDropzone.vue?vue&type=style&index=0&id=db36c932&prod&scoped=true&lang=css&"
import style1 from "./FileDropzone.vue?vue&type=style&index=1&id=db36c932&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db36c932",
  null
  
)

export default component.exports