<template>
  <v-overlay :value="overlay">
    <v-progress-circular indeterminate size="120">
      Carregando...
    </v-progress-circular>
  </v-overlay>
</template>
<script>
export default {
  name: "Loader",

  props: {
    overlay: {
      type: Boolean,
      default: false
    }
  }
}
</script>