<template>
	<v-container
		fluid
		class="mb-0 pb-0">
		<v-row
			no-gutters>
			<v-col
				cols="12"
				class="mt-0 pt-0">
				<v-row
					no-gutters
					class="d-flex justify-end align-center pr-2 mb-3">
					<v-col
						cols="12"
						xl="6"
						lg="6"
						md="6"
						sm="12"
						xs="12">
						<div
              class="d-flex flex-row align-center justify-start pl-2">
              <v-btn
                class="rounded-pill wine"
                icon
                height="42"
                width="42"
                @click="onClickBack()">
                <v-icon
                  color="white"
                  size="18">
                  fas fa-arrow-left
                </v-icon>
              </v-btn>
							<span
								class="ml-2 wine--text text-subtitle-2 font-weight-regular">
								Voltar
							</span>
            </div>
          </v-col>
					<v-col
						cols="12"
						xl="6"
						lg="6"
						md="6"
						sm="12"
						xs="12">
						<div
							v-if="bulkActionPermission"
							class="d-flex flex-row justify-end align-center">
							<span
								class="wine--text font-weight-light text-subtitle-2 mr-2">
								Ação em massa
							</span>
							<v-menu
								transition="slide-y-transition"
								offset-y>
								<template
									v-slot:activator="{ on, attrs }">
									<div
										class="wineSecondary d-flex justify-center align-center rounded-pill mr-2"
										style="height: 34px; width: 34px;"
										v-bind="attrs"
										v-on="on">
										<v-icon
											class="white--text">
											mdi-dots-vertical
										</v-icon>
									</div>
								</template>
								<TodoListActions
									:actions="bulkActions()"
								/>
							</v-menu>
							<v-checkbox
								v-model="selectAllFiles"
								color="wine"
								class="ma-0 pt-0"
								hide-details
							/>
							<span
								v-if="filesToReprocess.length > 0"
								class="wine--text text-subtitle-2 font-weight-regular">
								{{ filesToReprocess.length }} arquivos selecionados
							</span>
						</div>
					</v-col>
				</v-row>
			</v-col>
			<v-col
				cols="12"
				class="py-0">
				<v-data-table
					id="dataLoadDataTable"
					class="elevation-0 transparent"
					item-key="id"
					:items="dataLoads"
					:loading="loadingDataLoad"
					:items-per-page="itemsPerPage"
					:server-items-length="totalPages"
					@update:options="changePage($event)"
					:page="page"
					:custon-sort="{
						isDescending: true
					}"
					:footer-props="{
						itemsPerPageOptions: [10, 25, 50, 75, 100],
						disablePagination: loadingDataLoad
					}"
          hide-default-header>
					<template v-slot:no-data>
						<span>Nenhum dado encontrado.</span>
					</template>
					<template v-slot:loading>
						<div
							class="d-flex align-center justify-center">
							<v-progress-circular
								indeterminate
								color="wine"
							/>
						</div>
					</template>
					<template
						v-slot:header>
						<v-container
							fluid>
							<v-row
								class="px-2">
								<DataLoadHeader />
							</v-row>
						</v-container>
					</template>
					<template v-slot:item="{ item }">
						<v-sheet
							min-height="50"
							min-width="1400"
							class="my-2 elevation-3 rounded-lg d-flex align-center mx-2">
							<v-row
								no-gutters>
								<v-col
									v-bind="defaultColumnProps"
									cols="1"
									class="d-flex justify-space-between pr-2">
									<v-sheet
										height="50"
										:width="$vuetify.breakpoint.lgAndDown ? '50' : '66'"
										color="wine"
										:class="{
											'd-flex justify-center align-center elevation-0 rounded-l-lg': true,
											'pa-2': !$vuetify.breakpoint.lgAndDown,
											'pa-1': $vuetify.breakpoint.lgAndDown,
										}">
										<v-img
											height="30"
											contain
											:src="`icons/${item.subject}.png`"
										/>
									</v-sheet>
									<span
										v-bind="defaultSpanProps">
										{{ item.id }}
									</span>
								</v-col>
								<v-col
									v-bind="defaultColumnProps"
									cols="5">
									<v-sheet
										class="rounded-lg d-flex justify-end align-center"
										color="wineSecondary"
										height="40"
										width="910">
										<v-row
											class="d-flex align-center pl-2">
											<v-col
												:cols="$vuetify.breakpoint.lgAndDown ? 3 : 2">
												<span
													v-bind="defaultWhiteSpanProps">
													{{ formatter.formatDateTimeZoneWithHours(item.createdAt) }}
												</span>
											</v-col>
											<v-col
												:cols="$vuetify.breakpoint.lgAndDown ? 3 : 4">
												<v-row
													no-gutters>
													<v-tooltip bottom>
														<template v-slot:activator="{ on, attrs }">
															<div
																v-on="on"
																class="mr-2"
																style="max-width: 15vw;"
																v-bind="defaultWhiteSpanProps">
																{{ item.userName ?? '—' }}
															</div>
														</template>
														<span>
															{{ item.userName ?? '—' }}
														</span>
													</v-tooltip>
												</v-row>
											</v-col>
											<v-col
												cols="6"
												class="pl-0">
												<v-row
													no-gutters>
													<v-tooltip bottom>
														<template v-slot:activator="{ on, attrs }">
															<div
																v-on="on"
																style="max-width: 30vw;"
																v-bind="defaultWhiteSpanProps">
																{{ item.fileName }}
															</div>
														</template>
														<span>
															{{ item.fileName }}
														</span>
													</v-tooltip>
												</v-row>
											</v-col>
										</v-row>
									</v-sheet>
								</v-col>
								<v-col
									v-bind="defaultColumnProps"
									cols="2"
									class="pl-2">
									<v-tooltip
										bottom>
										<template v-slot:activator="{ on, attrs }">
											<div
												v-on="on"
												style="max-width: 15vw;">
												<div
													v-bind="defaultSpanProps">
													{{ item.carrierName ?? '—' }}
												</div>
											</div>
										</template>
										<span>
											{{ item.carrierName ?? '—' }}
										</span>
									</v-tooltip>
								</v-col>
								<v-col
									cols="4"
									v-bind="defaultColumnProps"
									class="pl-2">
									<v-row
										no-gutters>
										<v-col
											:cols="$vuetify.breakpoint.lgAndDown ? 4 : 5">
											<v-row
												no-gutters
												class="d-flex align-center justify-start">
												<v-sheet
													height="34"
													width="34"
													min-width="34"
													:class="{
														'rounded-circle d-flex justify-center align-center': true
													}"
													:color="getStatusColor(item.extProcessStatus)">
													<v-icon
														color="white"
														size="15">
														{{ getStatusIcon(item.extProcessStatus) }}
													</v-icon>
												</v-sheet>
												<span
													v-bind="defaultStatusSpanProps"
													class="pl-2">
													{{ getStatus(item.status) }}
												</span>
											</v-row>
										</v-col>
										<v-col
											class="d-flex align-center justify-start"
											:cols="$vuetify.breakpoint.lgAndDown ? 7 : 6">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<div
														v-on="on"
														v-bind="defaultSpanProps"
														:style="`max-width: ${$vuetify.breakpoint.lgAndDown ? '18vw' : '20vw'}`">
															{{ getExtProcessStatus(item.extProcessStatus) }}
													</div>
												</template>
												<span>
													{{ getExtProcessStatus(item.extProcessStatus) }}
												</span>
											</v-tooltip>
										</v-col>
										<v-col
											class="d-flex align-center justify-end"
											cols="1">
											<div
												class="d-flex flex-row align-center">
												<v-btn
													icon
													elevation="4"
													class="mr-1 white"
													v-show="validationViewCritics(item)"
													@click="openCriticsModal(item.id)">
													<v-icon
														color="wine">
														mdi-open-in-new
													</v-icon>
												</v-btn>
												<v-menu
													transition="slide-y-transition"
													offset-y>
													<template
														v-slot:activator="{ on, attrs }">
														<div
															class="wineSecondary d-flex justify-center align-center rounded-pill mr-2"
															style="height: 34px; width: 34px;"
															v-bind="attrs"
															v-on="on">
															<v-icon
																class="white--text">
																mdi-dots-vertical
															</v-icon>
														</div>
													</template>
													<TodoListActions
														:actions="getActions(item)"
													/>
												</v-menu>
												<div
													style="min-width: 34px;">
													<v-checkbox
														hide-details
														v-show="validationReprocessingFile(item)"
														:value="item.extProcessId"
														v-model="filesToReprocess"
														color="wine"
														class="ma-0 pa-0"
														multiple
													/>
												</div>
											</div>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-sheet>
					</template>
				</v-data-table>
			</v-col>
		</v-row>

		<ConfirmationModal
			ref="ConfirmationModal"
			@confirm="deleteFile"
			:title="'Excluir arquivo'"
			:description="confirmationModalDescription"
		/>
		<SnackbarCustomize ref="SnackbarCustomize" />
		<CriticsModal ref="CriticsModal" :hasComment="false"/>
		<Loader :overlay="loadingOverlay" />
	</v-container>
</template>

<script>
import SnackbarCustomize from "@/components/CustomAlerts/SnackbarCustomize.vue";
import TodoListActions from '@/components/TodoList/TodoListActions.vue';
import ConfirmationModal from "../Modals/ConfirmationModal/ConfirmationModal.vue";
import CriticsModal from "../Modals/CriticsModal/CriticsModal.vue";
import DataLoadService from "@/services-http/sdi/DataLoadService.js";
import DocumentService from "@/services-http/sdi/DocumentService";
import DataLoadMixin from "@/shared/mixins/dataLoad/dataLoadMixin";
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import { cloneDeep } from "lodash";
import DataLoadStore from '@/store/modules/data-load/data-load-module';
import moment from 'moment';
import Loader from '@/components/Loader/Loader.vue';
import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';
import Formatters from '@/shared/formatters/formatters';
import DataLoadHeader from "./DataLoadHeader.vue";

export default {
	name: "DataLoadTable",

	components: {
		SnackbarCustomize,
		ConfirmationModal,
		TodoListActions,
		DataLoadHeader,
		CriticsModal,
		Loader,
	},

	data: () => ({
		selectAllFiles: false,
		moment: moment,
		loadingRemoveFile: false,
		loadingDataLoad: false,
		loadingOverlay: false,
		hasAuthorityUploadDataLoad: false,
		propsLoadingTableMovementError: false,

		dataLoadService: null,
		documentService: null,

		page: 1,
		itemsPerPage: 10,
		totalPages: 0,

		propsTabError: '',
		confirmationModalDescription: null,
		propsInsuredName: '',
		propsMovementError: [],
		filesToReprocess: [],

		defaultColumnProps: {
			class: "d-flex justify-start align-center"
		},

		defaultSpanProps: {
			class: "text-truncate wineLabel--text text-subtitle-2 font-weight-regular"
		},

		defaultWhiteSpanProps: {
			class: "text-truncate white--text text-subtitle-2 font-weight-regular"
		},

		defaultStatusSpanProps: {
			class: "text-truncate wineTitle--text text-subtitle-2 font-weight-regular"
		},

	}),

	mixins: [
		DataLoadMixin,
		VerifyRoutesMixin,
		FinancialGroupsMixin,
		ContractsMixin,
		AuthorityManagementMixin,
	],

	props: {
		resetFilterDataLoad: {
			type: Boolean,
			default: false
		},
		financialGroups: { type: Array },
    insuranceCarriers: { type: Array },
    contracts: { type: Array },
    subcontracts: { type: Array },
	},

	watch: {
		filterDataLoad: {
			deep: true,
			immediate: true,
			handler(value) {
				if (value && !this.resetFilterDataLoad) {
					this.page = 1;
					this.$emit("hasFiltersDataLoad", this.filterDataLoad)
				}
			}
		},
		selectAllFiles: {
			deep: true,
			immediate: true,
			async handler(value) {
				if (value) {
					// await this.getReprocessDataLoads()
					this.filesToReprocess = this.dataLoads.filter(file => this.validationReprocessingFile(file)).map(item => item.extProcessId)
				} else{
					this.filesToReprocess = [];
				}
			}
		},
	},

	methods: {
		bulkActions() {
      const actions = [
				{
          label: 'Reprocessar arquivos',
          icon: 'fas fa-redo',
          color: 'wineLabel',
          condition: this.hasPermission('sdi_carga_fatura_acao_reprocessar_em_massa'),
          disabled: false,
          handler: () => this.reprocessFiles(),
        },
      ];
      actions.sort((a, b) => a.label.localeCompare(b.label));

      return actions;
    },
		getActions(item) {
      const actions = [
			{
          label: 'Download',
          icon: 'fas fa-file-download',
          color: 'wineLabel',
          condition: this.hasPermission('sdi_carga_fatura_acao_dowload'),
          disabled: false,
          handler: () => this.downloadDocument(item.id, item.fileName),
        },
        {
          label: 'Deletar',
          icon: 'fas fa-trash',
          color: 'wineLabel',
          condition: this.hasPermission('sdi_carga_fatura_acao_deletar'),
          disabled: !this.validationDeleteFile(item),
          handler: () => this.openConfirmationModal(item.id),
        },
				{
          label: 'Reprocessar',
          icon: 'fas fa-redo',
          color: 'wineLabel',
          condition: this.hasPermission('sdi_carga_fatura_acao_reprocessar'),
          disabled: !this.validationReprocessingFile(item),
          handler: () => this.reprocessFile(item),
        },
      ];
      actions.sort((a, b) => a.label.localeCompare(b.label));

      return actions;
    },
		// open DataLoadCriticsModal
		async openCriticsModal(idExternalFileData) {
			this.loadingOverlay = true;
			await this.dataLoadService.GetInvalidRecords(idExternalFileData)
			this.$refs.CriticsModal.open();
			this.loadingOverlay = false;
		},
    async getReprocessDataLoads () {
			const formParams = this.getFilteredParams();

			const filteredParams = {
				status: 'VALID',
				extProcessStatus: this.extProcessStatusFileToReprocess,
				startedDate: formParams.startedDate,
				endDate: formParams.endDate,
				page: this.page-1,
				size: this.itemsPerPage,
				sort: "id,desc"
			};
			this.loadingDataLoad = true;
			const params = new URLSearchParams({
				...filteredParams,
				page: this.page-1,
			})
			await this.dataLoadService.GetDataLoads(params).then((res) => {
				this.totalPages = res.data.totalElements;
        this.loadingDataLoad = false;
      }).catch(() => {
        this.loadingDataLoad = false;
				this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao buscar os arquivos.');
      }).finally(() => {
        this.loadingDataLoad = false;
      });
		},
		async getDataLoads () {
			const filteredParams = this.getFilteredParams();
			if (Object.entries(filteredParams).length === 0) return;
			if (filteredParams.filesToReprocess) {
				this.getReprocessDataLoads();
				return
			}
			this.loadingDataLoad = true;
			this.$emit('loadSearch', true)
			const params = this.buildSearchParams(filteredParams, "search");
			await this.dataLoadService.GetDataLoads(params).then((res) => {
				this.totalPages = res.data.totalElements;
        this.loadingDataLoad = false;
      }).catch(() => {
        this.loadingDataLoad = false;
				this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao buscar os arquivos.');
      }).finally(() => {
        this.loadingDataLoad = false;
				this.$emit('loadSearch', false)
      });
		},
		async reprocessFiles() {
			if (this.filesToReprocess.length === 0) {
				this.$refs.SnackbarCustomize.open('error', 'Selecione ao menos um arquivo para reprocessar.');
				return;
			}
			this.loading = true;
			const data = {ids: this.filesToReprocess}
			this.selectAllFiles = false;
			this.filesToReprocess = [];
			await this.dataLoadService.ReprocessFiles(data).then(async () => {
				this.$refs.SnackbarCustomize.open('success', 'Os arquivos foram reprocessados com sucesso.');
      }).catch(() => {
				this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao tentar reprocessar os arquivos.');
      }).finally(() => {
        this.loading = false;
				this.getDataLoads();
      });
		},
		async reprocessFile(item) {
			this.loading = true;
			const data = {ids: [item.extProcessId]}
			this.selectAllFiles = false;
			this.filesToReprocess = [];
			await this.dataLoadService.ReprocessFiles(data).then(async () => {
				this.$refs.SnackbarCustomize.open('success', 'Os arquivos foram reprocessados com sucesso.');
      }).catch(() => {
				this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao tentar reprocessar os arquivos.');
      }).finally(() => {
        this.loading = false;
				this.getDataLoads();
      });
		},
		cancelReprocess() {
			this.filesToReprocess = [];
		},
		async deleteFile(fileId) {
			this.confirmationModalDescription = '';
			this.loadingOverlay = true;
			await this.dataLoadService.RemoveFile(fileId).then(async () => {
				this.$refs.SnackbarCustomize.open('success', 'Arquivo removido com sucesso.');
      }).catch(() => {
				this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao tentar remover o arquivo.');
      }).finally(() => {
        this.loadingOverlay = false;
				this.getDataLoads();
      });
		},
		openConfirmationModal(fileId) {
			this.confirmationModalDescription = `Tem certeza que deseja excluir o arquivo nº${fileId}?`
			this.$refs.ConfirmationModal.open(fileId);
		},
		async changePage(event) {
			this.itemsPerPage = event.itemsPerPage;
      this.handlePageChange(event);
			this.updateDataTableScroll();
    },
		updateDataTableScroll() {
			const dataLoadTable = document.getElementById('dataLoadDataTable');
			if (dataLoadTable) {
				dataLoadTable.firstElementChild.scrollTo({
					top: 0,
					behavior: 'smooth'
        });
			}
		},
		validationReprocessingFile(item) {
			return item.status === 'VALID'
				&& this.extProcessStatusFileToReprocess.some(status => status === item.extProcessStatus)
				&& this.bulkActionPermission
		},
		validationDeleteFile(item) {
			return this.extProcessStatusFileToDelete.some(status => status === item.extProcessStatus)
		},
		validationViewCritics(item) {
			return this.hasPermission('sdi_carga_fatura_visualizar_criticas')
						 && this.extProcessStatusFileToViewCritics.some(status => status === item.extProcessStatus)
		},
		async handlePageChange(event) {
      const { page: newPage, itemsPerPage } = event;
      this.page = newPage;
      this.itemsPerPage = itemsPerPage;
      await this.getDataLoads();
    },
		async downloadDocument(idDocument, fileName) {
			this.loadingOverlay = true;

      await this.dataLoadService.DownloadFileDocuments(idDocument).then((response) => {
        if (response) {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink);
          fileLink.click();

          this.loadingOverlay = false;
					this.$refs.SnackbarCustomize.open('success', 'Download realizado com sucesso.', 3000);
        }
      }).catch((error) => {
		if(error && error.response && error.response.status === 412){
			this.readBlobAsText(error.response.data).then(text => {
				this.$refs.SnackbarCustomize.open('error', text)
                }).catch(error => {
                    this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao tentar efetuar o upload dos arquivos.');
                });
        } else {
          this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao tentar efetuar o upload dos arquivos.');
        }
        this.loadingOverlay = false;
      }).finally(() => {
        this.loadingOverlay = false;
      });
		},
		readBlobAsText(blob) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = function(event) {
                    resolve(event.target.result);
                };
                reader.onerror = function(event) {
                    reject(new Error("File could not be read: " + event.target.error));
                };
                reader.readAsText(blob);
            });
        },
		// searches the financial group presented in the data-table
		getFinancialGroup(financialGroupId) {
			return this.financialGroups.find(el => el.id === financialGroupId) || '—';
		},
		// Assembles and returns the parameters of the filterDataLoad object
		getFilteredParams() {
			return Object.entries(this.filterDataLoad)
				.filter(([key, value]) => value && value !== "")
				.reduce((element, [key, value]) => ({ ...element, [key]: value }), {});
		},
		// Assembles and returns the parameters that will be sent in the request
		buildSearchParams(filteredParams, type) {
			const formattedFilterParams = cloneDeep(filteredParams)

			this.deleteEmptyParams(formattedFilterParams)

			return new URLSearchParams({
				...formattedFilterParams,
				page: this.page-1,
				size: this.itemsPerPage,
				sort: "id,desc"
			})
		},
    // set object to filter page data
		async handlerFilter(value, type) {
			this.page = 1
			this.filterDataLoad = cloneDeep(value)
			await this.getDataLoads()
		},
		// Remove parameters with value equal to '', null or undefined
		deleteEmptyParams(params) {
			Object.keys(params).forEach((key) => {
				if (["", null, undefined].some(item => item === params[key])) delete params[key]
			})
		},
		// return to the route that has all the menus
		onClickBack() {
      this.redirectRouter('HomeAllMenus', { isRHProtegido: this.isRHProtegido });
    },
	},
	computed: {
		dataLoads() {
			return DataLoadStore.fileDataLoads;
		},
		bulkActionPermission() {
			return this.hasPermission('sdi_carga_fatura_acao_reprocessar_em_massa');
		},
	},
	created() {
		this.dataLoadService = new DataLoadService();
		this.documentService = new DocumentService();
		this.formatter = new Formatters();
		this.loadingDataLoad = true;
	},
}
</script>

<style>
#dataLoadDataTable .v-data-table__progress {
  display: none;
}
</style>
