import DataLoadStore from '@/store/modules/data-load/data-load-module';
import HttpService from '../HttpService'

export default class DataLoadService {
  constructor() {
    this._httpConfigService = new HttpService('/sdi/config_mandatory_ext_file');
    this._httpService = new HttpService('/sdi/external_file_data');
    this._httpServiceInvoice = new HttpService('/invoice-service');
  }

  async GetConfigMandatoryExtFile(subject) {
    return await this._httpConfigService.get(`?subject=${subject}`, {});
  };

  async GetAllConfigMandatoryExtFile() {
    return await this._httpConfigService.get(``, {});
  };

  async GetDataLoads(params) {
    const result =  await this._httpService.get('', params);
    DataLoadStore.setDataLoads(result.data.content)
    return result
  };

  async GetAllConfigMandatoryExtFile() {
    return await this._httpConfigService.get(``, {});
  };

  async GetFileTypes() {
    const result = await this._httpService.get(`/subjects`);
    DataLoadStore.setFileTypes(result.data);
  };

  async UploadFiles(data) {
    let formData = new FormData()
    data.files.forEach((file) => {
      formData.append('files', file)
    });
    delete data.files;

    let paramString = Object.entries(data)
    .reduce((acc, [key, value]) => {
      if (Boolean(value)) {
        return acc === '?' ? `${acc}${key}=${value}` : `${acc}&${key}=${value}`;
      }
      return acc;
    }, '?');

    return await this._httpService.post(`/${paramString}`, formData);
  };

  async DownloadFileDocuments(ids){
    return await this._httpService.get(`/download-documents/${ids}`, null, { responseType: 'blob' });
  }

  async GetInvalidRecords(idExternalFileData) {
    const result = await this._httpService.get(`/invalid_records/${idExternalFileData}`);
    DataLoadStore.setDataErrors(result.data);
  };

  async RemoveFile(fileId) {
    return await this._httpService.delete(`/delete/${fileId}`);
  };

  async ReprocessFiles(filesId) {
    return await this._httpService.patch('/reprocess', filesId);
  };

  async CreateCarrierInvoiceValidation(params) {
    return await this._httpServiceInvoice.post('/create-validation', params);
  }
}