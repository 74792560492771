<template>
  <div>
    <v-container
      fluid>
      <v-row
        class="mb-5">
        <v-col
          cols="12"
          class="pb-0 ml-1">
          <span
            class="text-subtitle-1"
            style="color: #B9B0B0">
            CARGA DE DADOS
          </span>
        </v-col>
      </v-row>
      <v-expansion-panels
        class="px-1 elevantion-1 rounded-t-lg rounded-b-0"
        v-if="hasPermission('sdi_carga_fatura_acao_upload')">
        <v-expansion-panel>
          <v-expansion-panel-header
            class="py-4">
            <span
              class="text-h6 font-weight-bold"
              style="color: #504F4F">
              Novo Upload
            </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form
            ref="DataUploadForm">
            <v-row
              class="mt-4">
              <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="6"
                xs="12"
                :order="$vuetify.breakpoint.smAndDown ? '2' : '1'">
                <FileRegisterFields
                  ref="FileRegisterFields"
                  :financialGroups="financialGroups"
                  :insuranceCarriers="insuranceCarriers"
                  :contracts="contracts"
                  :subcontracts="subcontracts"
                  :validateForm="validateForm"
                    :reset="reset"
                />
              </v-col>
              <v-col
                xl="6"
                lg="6"
                md="5"
                sm="12"
                xs="12"
                :order="$vuetify.breakpoint.smAndDown ? '1' : '2'">
                <FileDropzone
                  ref="FileDropzone"
                  :validateForm="validateForm"
                    :reset="reset"
                />
              </v-col>
              <v-col
                cols="12"
                xl="2"
                lg="2"
                md="3"
                sm="6"
                xs="12"
                order="3">
                <FileCounter
                  :loading="loading"
                  @uploadFiles="uploadFiles"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
      <div
        class="mb-2">
        <DataLoadFilter
          ref="DataLoadFilter"
          :financialGroups="financialGroups"
          :insuranceCarriers="insuranceCarriers"
          :contracts="contracts"
          :subcontracts="subcontracts"
          :resetFilterDataLoad="resetFilterDataLoad"
          :filtersForCheckDate="filtersForCheckDate"
          :loadSearch="loadSearch"
          @handlerClickFilter="handlerClickFilter"
          @resetStatusFilters="resetStatusFilters"
          @hasFiltersDataLoad="hasFiltersDataLoad"
          @clearFilters="clearFilters"
          @openExpandedContent="openExpandedContent"
        />
      </div>
      <v-row>
        <DataLoadTable
          ref="DataLoadTable"
          :financialGroups="financialGroups"
          :insuranceCarriers="insuranceCarriers"
          :contracts="contracts"
          :subcontracts="subcontracts"
          @loadSearch="loadSearch = $event"
        />
      </v-row>
    </v-container>
    <SnackbarCustomize ref="SnackbarCustomize" />
  </div>
</template>
<script>
import DataLoadContentFilter from '@/components/DataLoad/DataLoadContentFilter.vue';
import FileRegisterFields from '@/components/DataLoad/FileRegisterFields.vue';
import CustomFilter from '@/components/CustomFilter/CustomFilter.vue';
import DataLoadFilter from '@/components/DataLoad/DataLoadFilter.vue';
import DataLoadTable from '@/components/DataLoad/DataLoadTable.vue';
import FileDropzone from '@/components/DataLoad/FileDropzone.vue';
import FileCounter from '@/components/DataLoad/FileCounter.vue';
import Rules from '@/shared/validators/formRules';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';

import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';
import GenericFunctionsMixin from '@/shared/mixins/genericFunctions/genericFunctionsMixin';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import DataLoadMixin from '@/shared/mixins/dataLoad/dataLoadMixin';

import DataLoadStore from '@/store/modules/data-load/data-load-module';

import DataLoadService from '@/services-http/sdi/DataLoadService';

import { cloneDeep } from 'lodash';
import moment from 'moment';

export default {
  components: {
    DataLoadContentFilter,
    FileRegisterFields,
    DataLoadFilter,
    DataLoadTable,
    CustomFilter,
    FileDropzone,
    FileCounter,
    SnackbarCustomize,
  },

  data: () => ({
    loading: false,
    panelExpand: false,
    validateForm: true,
    resetFilterDataLoad: false,
    filtersForCheckDate: {},
    filterDataLoad: {},
    financialGroups: [],
    dataFilterDataLoad: {},
    dataLoadService: null,
    rule: null,
    dates: [],
    dateType: [],

    insuranceCarriers: [],
    financialGroups: [],
    contracts: [],
    subcontracts: [],
    columnFooterProps: {
      cols: "12",
      xs: "12",
      sm: "12",
      md: "2",
      lg: "2",
    },
    reset: false,
    loadSearch: false,
  }),

  mixins: [
    DataLoadMixin,
    VerifyRoutesMixin,
    FinancialGroupsMixin,
    GenericFunctionsMixin,
    InsuranceCarriersMixin,
    AuthorityManagementMixin,
  ],

  methods: {
    fieldsValidation() {
      const fieldsToValidate = ['financialGroupId', 'contractId', 'subcontractId', 'carrierId']
      const fieldsValidated = this.rule.hasAtLeastOneField(DataLoadStore.dataLoad, fieldsToValidate);
      if (!fieldsValidated) {
        this.validateForm = false
        this.loading = false;
        return false;
      } else {
        return true;
      }
    },
    async uploadFiles() {
      this.loading = true;
      this.validateForm = true;
      this.dataLoad = DataLoadStore.dataLoad;
      this.dataLoad.files = DataLoadStore.dataFiles;

      if (!this.$refs.DataUploadForm.validate()) {
        this.fieldsValidation();
        this.loading = false;
        return;
      };

      const validateFields = this.fieldsValidation();
      if (!validateFields) {
        this.validateForm = false;
        this.loading = false;
        return
      }

      const validateFiles = this.rule.validateAllFiles(this.dataLoad.files);

      if (!validateFiles) {
        this.validateForm = false;
        this.loading = false;
        return
      }

      this.validateForm = true;

      this.dataLoad = this.setMonthYearReference(this.dataLoad);
      await this.dataLoadService.UploadFiles(this.dataLoad).then(async () => {
        this.loading = false;
        this.$refs.DataUploadForm.reset()
        this.resetPage();
        this.requestDataTable()
        this.$refs.SnackbarCustomize.open('success', 'Os arquivos foram enviados com sucesso.');
      }).catch((error) => {
        this.loading = false;
        if(error && error.response && error.response.status===412){
          this.$refs.SnackbarCustomize.open('error', error.response.data);
        } else {
          this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao tentar efetuar o upload dos arquivos.');
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    resetPage() {
      this.reset = true;
      this.dates = [];
      this.resetDataLoadObj();
      DataLoadStore.cleanDataLoad();
      DataLoadStore.setCounter(0);
      DataLoadStore.setDataFiles([]);
      DataLoadStore.setDataLoad({
        uploadType: '',
        createdBy: '',
        carrierId: '',
        financialGroupId: '',
        contractId: '',
        subContractId: '',
        status: '',
        competence: '',
      });
      setTimeout(() => {
        this.reset = false;
      }, 1000);
    },
    // search data from defined filters
    handlerClickFilter(dates, dateType) {
      if (dates && dates.length > 0) {
        this.processDates(dates, dateType);
      } else {
        this.dates = [];
      }

      const commonFilterProperties = this.setCommonFilterProperties();

      this.handleDataLoad(commonFilterProperties)
    },
    processDates(dates, dateType) {
      const startDate = moment(dates[0]);
      const endDate = moment(dates[1]);
      this.dateType = dateType;

      this.dates[0] = this.setDateTimezone(startDate.startOf('day'))
      this.dates[1] = this.setDateTimezone(endDate.endOf('day').add(3, 'hours'))

      this.dates[0] = this.formatDate(this.dates[0])
      this.dates[1] = this.formatDate(this.dates[1])
    },
    setDateTimezone(date) {
      return date.add(3, 'hours')
    },
    formatDate(date) {
      return date.format('YYYY-MM-DD');
    },
    requestDataTable() {
      setTimeout(() => {
        this.$refs.DataLoadFilter.handlerFilter();
      }, 100);
    },
    resetStatusFilters(data) {
      this.resetFilterDataLoad = data;
    },
    // set common filters
    setCommonFilterProperties() {
      return {
        startDate: this.dates[0],
        endDate: this.dates[1],
        dateType: this.dateType,
      };
    },
    // set object to filter page data
    handleDataLoad(commonFilterProperties) {
      this.resetFilterDataLoad = false;

      this.filterDataLoad = {
        ...this.dataFilterDataLoad,
        startedDate: this.dates[0],
        endDate: this.dates[1],
      }

      this.$refs.DataLoadTable.handlerFilter(this.filterDataLoad, 'click');
    },
    hasFiltersDataLoad(data, type) {
      this.dataFilterDataLoad = cloneDeep(data);
      this.filtersForCheckDate = cloneDeep(data);
      this.dataFilterDataLoad = this.setMonthYearReference(this.dataFilterDataLoad);
    },
    setMonthYearReference(data) {
      if (!Boolean(data.competence)) return data
      data.monthReference = moment(data.competence, 'MM/YYYY').format('MM')
      data.yearReference = moment(data.competence, 'MM/YYYY').format('YYYY')
      delete data.competence;
      return data
    },
    // clear fields from the CustomFilter component
    clearFilters() {
      this.$refs.DataLoadFilter.clearFilterDataLoad();
    },
    // function executed only when the "Nenhuma Seleção de Data" option was set in the date filter
    // so the user can set other filters to optimize the search
    openExpandedContent(expand) {
      this.panelExpand = expand;
    },
  },

  created() {
    this.resetPage();
    this.dataLoadService = new DataLoadService();
  },

  async mounted() {
    this.rule = new Rules();
    this.getFinancialGroups();
    this.getInsuranceCarriers();
    await this.dataLoadService.GetFileTypes();
    this.requestDataTable()
    this.reset = false;
  },
}
</script>
