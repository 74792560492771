<template>
	<v-container
		fluid
		class="mx-0 px-0">
		<v-tabs
			v-model="tab"
			class="mb-4"
			color="wine">
			<v-tab
				key="searchFilters"
				href="#searchFilters"
				style="font-size: 16px"
				aria-label="Nova Pesquisa">
				<v-icon dense class="mr-2">
					fas fa-search
				</v-icon>
				Nova Pesquisa
			</v-tab>
			<v-tab
				key="favoriteFilters"
				href="#favoriteFilters"
				style="font-size: 16px"
				aria-label="Pesquisas Salvas">
        <v-icon
					dense
					class="mr-2">
					far fa-bookmark
				</v-icon>
        Pesquisas Salvas
      </v-tab>
		</v-tabs>
		<v-tabs-items
			style="border-radius: 5px;"
			v-model="tab"
			grow>
			<v-tab-item
				key="searchFilters"
				value="searchFilters">
				<v-row
					v-show="!overlay"
					class="mt-2 wine--text">
					<v-col
						v-bind="columnsProps">
						<v-autocomplete
							v-model="filterDataLoad.subject"
							label="Tipo de arquivo"
							v-bind="autoCompleteProps"
							:items="getDataLoadFileTypes"
						/>
					</v-col>
					<v-col
						v-bind="columnsProps">
						<v-autocomplete
							v-model="filterDataLoad.carrierId"
							label="Operadora"
							v-bind="autoCompleteCarrierProps"
							:items="insuranceCarriers"
						/>
					</v-col>
					<v-col
						v-bind="columnsProps">
						<v-autocomplete
							v-model="filterDataLoad.financialGroupId"
							label="Grupo financeiro"
							v-bind="autoCompleteProps"
							:items="financialGroups"
						/>
					</v-col>
					<v-col
						v-bind="columnsBigProps">
						<v-autocomplete
							v-model="filterDataLoad.contractId"
							label="Contrato"
							v-bind="autoCompleteProps"
							:items="contracts"
							:loading="loadingContracts"
						/>
					</v-col>
					<v-col
						v-bind="columnsBigProps">
						<v-autocomplete
							v-model="filterDataLoad.subContractId"
							label="Subcontrato"
							v-bind="autoCompleteProps"
							:items="subContracts"
							:loading="loadingSubContracts"
						/>
					</v-col>
					<v-col
						v-bind="columnsProps">
						<v-text-field
							v-model="filterDataLoad.id"
							label="Nº Protocolo"
							v-bind="textFieldProps"
						/>
					</v-col>
					<v-col
						v-bind="columnsProps">
						<v-menu
							ref="menu"
							v-model="menu"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
							max-width="290px"
							min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="filterDataLoad.competence"
									placeholder="Selecione"
									label="Competência"
									readonly
									outlined
									dense
									hide-details
									v-bind="autoCompleteProps"
									v-on="on"
								/>
							</template>
							<v-date-picker
								@click="setCompentence"
								v-model="date"
								type="month"
								locale="pt-br"
								color="wine"
								no-title
								scrollable>
								<v-spacer />
								<v-btn
									text
									@click="cleanCompetence">
									LIMPAR
								</v-btn>
								<v-btn
									text
									@click="setCompentence">
									OK
								</v-btn>
							</v-date-picker>
						</v-menu>
					</v-col>
					<v-col
						v-bind="columnsProps">
						<v-autocomplete
							v-model="filterDataLoad.status"
							label="Etapa"
							v-bind="autoCompleteProps"
							:items="getStatusList"
						/>
					</v-col>
					<v-col
						v-bind="columnsBigProps">
						<v-autocomplete
							v-model="filterDataLoad.extProcessStatus"
							label="Status"
							v-bind="autoCompleteProps"
							:items="getExtProcessStatusList"
						/>
					</v-col>
					<v-col
						v-bind="columnsBigProps">
						<v-text-field
							v-model="filterDataLoad.createdBy"
							label="Criado por"
							v-bind="textFieldProps"
						/>
					</v-col>
				</v-row>
				<v-row v-show="overlay" class="wine--text my-15">
					<v-col cols="12" class="d-flex justify-center">
						<v-progress-circular
							color="wine"
							indeterminate
							size="64"
							width="8"
						/>
					</v-col>
					<v-col cols="12" class="d-flex justify-center"> Carregando Filtros </v-col>
				</v-row>
			</v-tab-item>
			<v-tab-item key='favoriteFilters' value='favoriteFilters'>
        <FavoriteSearchComponent
          filterBy="DataLoadContentFilter"
					:defaultColor="'wine'"
          :searchFilter="filterDataLoad"
          :changeTabValue="changeTabValue"
          @loadThisFilter="loadThisFilter"
        />
      </v-tab-item>
		</v-tabs-items>
	</v-container>
</template>

<script>
import DataLoadMixin from '@/shared/mixins/dataLoad/dataLoadMixin';
import FavoriteSearchComponent from '@/components/FavoriteSearchFilters/FavoriteSearchComponent.vue';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import DataLoadStore from '@/store/modules/data-load/data-load-module';
import moment from 'moment';
import lodash from 'lodash';

export default {
	name: "DataLoadContentFilter",

	components: {
		FavoriteSearchComponent
	},

	data: () => ({
		overlay: false,
		tab: "searchFilters",
		menu: false,
		date: null,
    changeTabValue: 'FILE_DATA',
		columnsProps: {
			cols: "12",
			sm: "6",
			md: "4",
			lg:"2",
			class: "py-0",
		},
		columnsBigProps: {
			cols: "12",
			sm: "6",
			md: "4",
			lg:"3",
			class: "py-0",
		},
		autoCompleteProps: {
			class: "mt-1",
			placeholder: "Selecione",
			itemText: "name",
			itemValue: "id",
			outlined: true,
			clearable: true,
			dense: true,
			color: "wine--text",
			itemColor: "wine--text",
		},
		autoCompleteCarrierProps: {
			class: "mt-1",
			placeholder: "Selecione",
			itemText: "xipp_commercial_name",
			itemValue: "id",
			outlined: true,
			clearable: true,
			dense: true,
			color: "wine--text",
			itemColor: "wine--text",
		},
		textFieldProps: {
			class: "mt-1",
			outlined: true,
			clearable: true,
			dense: true,
			color: "wine--text",
			itemColor: "wine--text",
		},
		contracts: [],
		subContracts: [],
		loadingContracts: false,
    loadingSubContracts: false,
    watcherCalledGetContract: false,
    watcherCalledGetSubContract: false,
		overlay: false,
	}),

	mixins: [ DataLoadMixin ],

	props: {
		financialGroups: { type: Array },
    insuranceCarriers: { type: Array },
		resetFilterDataLoad: {
			type: Boolean,
			default: false
		}
	},

	mixins: [
		InsuranceCarriersMixin,
		ContractsMixin,
		DataLoadMixin,
	],

	watch: {
		date: {
      deep: true,
      handler(value) {
        if (value) this.setCompentence();
      },
    },
		filterDataLoad: {
			deep: true,
			immediate: true,
			handler(value) {
				if (value) this.$emit("hasFiltersDataLoad", this.filterDataLoad)
			}
		},
		resetFilterDataLoad: {
			deep: true,
			handler(value) {
				if (value) this.clearFilterDataLoad()
			}
		},
    'filterDataLoad.financialGroupId': {
      deep: true,
      handler(value) {
				this.filterDataLoad.contractId = null;
				this.filterDataLoad.subContractId = null;
        const financialGroupId = lodash.cloneDeep(value);
        if (financialGroupId) {
          if (!this.watcherCalledGetContract) {
            this.loadContractsByFinancialGroupId(financialGroupId);
          }
        } else {
          this.contracts = [];
          this.subContracts = [];
          this.watcherCalledGetContract = false;
        }
      },
    },
    'filterDataLoad.contractId': {
      deep: true,
      handler(value) {
				this.filterDataLoad.subContractId = null;
        if (value) {
          if (!this.watcherCalledGetSubContract) {
            this.loadSubContractsByContractId(value);
          }
        } else {
          this.subContracts = [];
          this.watcherCalledGetSubContract = false;
        }
      },
    },
  },

	methods: {
		// clears the page filters object
		clearFilterDataLoad() {
			this.filterDataLoad = {
				uploadType: '',
				userName: '',
				carrierId: '',
				financialGroupId: '',
				contractId: '',
				subContractId: '',
				status: '',
				extProcessStatus: '',
				competence: '',
      },
			this.$forceUpdate()
			this.$emit("resetStatusFilters", false)
		},
		loadThisFilter(data) {
      this.setOverlay(true);
      this.filterDataLoad = {
        subject: data.queryParams.subject ? data.queryParams.subject : '',
        createdBy: data.queryParams.createdBy ? data.queryParams.createdBy : '',
        carrierId: data.queryParams.carrierId ? parseInt(data.queryParams.carrierId) : '',
        financialGroupId: data.queryParams.financialGroupId ? parseInt(data.queryParams.financialGroupId) : '',
        contractId: data.queryParams.contractId ? parseInt(data.queryParams.contractId) : '',
        subContractId: data.queryParams.subContractId ? parseInt(data.queryParams.subContractId) : '',
        id: data.queryParams.id ? data.queryParams.id : '',
        status: data.queryParams.status ? data.queryParams.status : '',
				extProcessStatus: data.queryParams.extProcessStatus ? data.queryParams.extProcessStatus : '',
				competence: data.queryParams.competence ? data.queryParams.competence : '',
      };

      this.tab = 'searchFilters';
      this.$emit('hasFiltersDataLoad', this.filterDataLoad);

      setTimeout(() => {
        this.setOverlay(false);
      }, 500);
    },
		setOverlay(value) {
      this.overlay = value;
    },
		loadContractsByFinancialGroupId(financialGroupId) {
      return new Promise((resolve) => {
        this.getContractsByFinancialGroupId(financialGroupId).then(() => {
          resolve();
        });
      });
    },
    loadSubContractsByContractId(contractId) {
      return new Promise((resolve) => {
        this.getSubContractsByContractId(contractId).then(() => {
          resolve();
        });
      });
    },
		setCompentence() {
			if (Boolean(this.date)) {
				this.filterDataLoad.competence = moment(this.date, 'YYYY-MM').format('MM/YYYY');
			}
      this.menu = false;
    },

    cleanCompetence() {
      this.filterDataLoad.competence = null;
			this.date = null;
    },
	},
}
</script>
