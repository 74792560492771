<template>
  <v-row
    no-gutters>
    <v-col cols="12">
      <v-sheet
        height="125"
        wifth="100%"
        elevation="1"
        rounded="xl"
        color="#FCFCFC">
        <v-row
          no-gutters>
          <v-col
            cols="12"
            class="d-flex justify-center align-end wine--text text-h4 font-weight-regular"
            style="height: 62px;">
            {{ fileCounter }}
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center align-start">
            <span
              class="wine--text text-h6 font-weight-regular text-center">
              Arquivos carregados
            </span>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
    <v-col
      cols="12"
      class="d-flex justify-center mt-2">
      <v-btn
        @click="uploadFiles"
        color="wine"
        class="white--text"
        :loading="loading"
        rounded
        large
        block
        elevation="0">
        Upload
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import DataLoadMixin from '@/shared/mixins/dataLoad/dataLoadMixin';
import DataLoadStore from '@/store/modules/data-load/data-load-module';
import DataLoadService from '@/services-http/sdi/DataLoadService';

export default {
  name: "FileCounter",

  data: () => ({
    dataLoadService: null,
  }),

  mixins: [
    DataLoadMixin
  ],

  props: {
    loading: {
      type: Boolean
    }
  },

  methods: {
    uploadFiles() {
      this.$emit("uploadFiles");
    },
  },

  computed: {
    fileCounter() {
      return DataLoadStore.fileCounter;
    }
  },

  mounted() {
    this.dataLoadService = new DataLoadService();
  },
}
</script>