<template>
  <v-dialog
    max-width="790"
    v-model="modal">
    <template v-slot:default="dialog">
      <v-card
        class="rounded-lg">
        <v-toolbar
          width="100%"
          color="wine"
          height="79"
          style="position: relative;">
          <v-row
            class="text-center d-flex justify-center">
            <span
              class="text-h5 white--text font-weight-bold">
              Críticas
            </span>
            <v-btn
              icon
              class="white elevation-4"
              color="wine"
              heiht="43"
              max-height="43"
              width="43"
              style="position: absolute; right: 5%; top: 20%;"
              @click="close">
              <v-icon
                size="30">
                close
              </v-icon>
            </v-btn>
          </v-row>
        </v-toolbar>
        <v-card-text
          style="height: 630px;"
          class="mt-5">
          <span
            class="wine--text text-h6 font-weight-regular pl-2">
            Total de erros: {{errors.length}}
          </span>
          <v-container
            fuild
            class="mt-5"
            style="max-height: 560px; overflow-y: auto;">
            <div
              v-for="(item, index) in errors"
              :key="index">
              <v-sheet
                elevation="4"
                class="pl-4 mb-6 mt-1"
                key="i">
                <v-row>
                  <v-col
                    cols="10">
                    <v-row no-gutters>
                      <v-col
                        cols="12">
                        <span
                          class="wineTitle--text text-h6 font-weight-regular">
                          {{ moment(new Date(item.createdAt)).format('DD/MM/YYYY') }}
                        </span>
                      </v-col>
                      <v-col
                        cols="12">
                        <span
                          class="wineLabel--text text-h6 font-weight-regular">
                          {{ item.message }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="">
                        <span
                          class="wineTitle--text text-h6 font-weight-regular">
                          Linha
                        </span>
                      </v-col>
                      <v-col
                        cols="12">
                        <span
                          class="wineLabel--text text-h6 font-weight-regular">
                          {{ item.line }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-sheet>
            </div>
            <v-row
              v-if="hasComment">
              <v-col
                cols="12">
                <v-textarea
                  placeholder="Digite aqui..."
                  id="textAreaCriticsModal"
                  outlined>
                  <template
                    v-slot:label>
                    <span
                      class="wineTitle--text text-h6">
                      Comentário
                    </span>
                  </template>
                </v-textarea>
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-center">
                <v-btn
                  height="50"
                  width="260"
                  color="wine"
                  class="rounded-pill">
                  <span
                    class="white--text text-h6">
                    Salvar
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
 import DataLoadStore from '@/store/modules/data-load/data-load-module';
 import moment from 'moment';
export default {
  name: "CriticsModal",

  data: () => ({
    modal: false,
    moment: moment,
  }),

  props: {
    hasComment: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    open() {
      this.modal = true;
    },
    close() {
      this.modal = false;
    },
  },

  computed: {
    errors() {
      return DataLoadStore.dataErrors;
    }
  },
}
</script>

<style>
label[for="textAreaCriticsModal"] {
  height: 110px !important;
}
</style>