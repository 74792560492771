<template>
  <div
    class="custom-file-input d-flex align-center justify-center"
    @dragover.prevent
    @drop="handleDrop"
    @dragenter="handleDragEnter"
    @dragleave="handleDragLeave"
    :style="!validateForm && dataLoad.files.length === 0 ? 'border: 2px dashed #e63946;' : ''">
    <v-container
      style="pointer-events: none;">
      <v-row
        no-gutters
        justify="center"
        style="pointer-events: none; width: 100%;">
        <v-col
          cols="12"
          v-if="dataLoad.files.length === 0"
          class="d-flex flex-column justify-center align-center"
          style="pointer-events: none;">
          <v-img src="icons/uploadFile.png" />
          <label
            class="wineTitle--text">
            {{ uploadMessage }}
          </label>
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center"
          v-if="dataLoad.files.length === 0 && !isHandleDragEnter">
          <h2
            class="dashLine">
            <span
              class="white px-3 wineTitle--text">
              Ou
            </span>
          </h2>
        </v-col>
        <v-col
          cols="12">
          <v-file-input
            v-show="dataLoad.files.length > 0"
            ref="FileInput"
            accept=".xls,.xlsx,.csv,.txt,.pdf"
            multiple
            chips
            hide-details
            v-model="dataLoad.files"
            prepend-icon=""
            append-icon=""
            :clearable="false"
            truncate-length="100"
            style="
              max-height: 120px;
              overflow: auto;
              width: 100%;">
            <template v-slot:selection="{index, text}">
              <div
                class="d-block mb-2"
                style="width: 100%; pointer-events: auto;">
                <v-sheet
                  close
                  large
                  height="50"
                  max-width="100%"
                  style="background: transparent linear-gradient(90deg, #BA98B4 0%, #5D4C5A 100%) 0% 0% no-repeat padding-box;"
                  class="white--text rounded-pill d-flex align-center px-2">
                  <div
                    class="text-truncate pr-2">
                    {{ text }}
                  </div>
                  <v-spacer />
                  <v-btn
                    icon
                    class="white"
                    color="#BA98B4"
                    style="pointer-events: auto;"
                    @click.stop="removeFile(index)">
                    <v-icon
                      size="30">
                      close
                    </v-icon>
                  </v-btn>
                </v-sheet>
              </div>
            </template>
          </v-file-input>
        </v-col>
        <v-col
          cols="12"
          class="d-flex flex-column justify-center align-center mt-2">
          <v-btn
            v-if="!isHandleDragEnter"
            color="#BA98B4"
            class="white--text"
            rounded
            large
            elevation="0"
            style="pointer-events: auto;"
            @click="openFileFolder">
            Carregar arquivos
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Formatters from '@/shared/formatters/formatters';
import DataLoadMixin from '@/shared/mixins/dataLoad/dataLoadMixin';
import DataLoadStore from '@/store/modules/data-load/data-load-module';
export default {
  name: "FileDropzone",
  data: () => ({
    formatter: null,

    defaultClassColumn: 'py-0',

    uploadMessage: 'Arraste aqui',
    isHandleDragEnter: false,
  }),

  mixins: [
    DataLoadMixin
  ],

  watch: {
    dataLoad: {
      handler(val) {
        DataLoadStore.setCounter(val.files.length);
        DataLoadStore.setDataFiles(val.files);
      },
      deep: true,
    }
  },

  props: {
    validateForm: {
      type: Boolean
    }
  },

  methods: {
    openFileFolder() {
      this.$refs.FileInput.$refs.input.click();
    },
    removeFile(index) {
      this.dataLoad.files.splice(index, 1);
      DataLoadStore.setCounter(this.dataLoad.files.length);
    },
    async handleDrop(event) {
      if (event && event.dataTransfer && event.dataTransfer.files) {
        const files = Array.from(event.dataTransfer.files);
        DataLoadStore.setCounter(files.length);
        this.dataLoad.files = files;
        event.preventDefault();
        this.handleDragLeave();
      }
    },
    handleDragEnter(event) {
      event.preventDefault();
      this.uploadMessage = 'Solte os arquivos aqui';
      this.isHandleDragEnter = true;
    },
    handleDragLeave() {
      this.uploadMessage = 'Arraste aqui';
      this.isHandleDragEnter = false;
    },
  },
  mounted() {
    this.formatter = new Formatters();
  },
}
</script>
<style scoped>
.custom-file-input {
  height: 100%;
  width: 100%;
  border: 2px dashed #E6E5E5;
  border-radius: 10px;
  position: relative;
}
.dashLine {
  width: 300px;
  text-align: center;
  border-bottom: 1px solid #B9B0B0;
  line-height: 0.1em;
  margin: 10px 0 20px;
}
</style>
<style>
#FileInputDropzone > div > div > div {
  border-color: transparent !important;
}
#FileInputDropzone > div > div > div::before {
  border-color: transparent !important;
}
#FileInputDropzone > div > div > div::after {
  border-color: transparent !important;
}
</style>